.story__controls {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 4;
    font-size: 12px;
  }
  
  .story__controls button {
    margin-left: 5px;
  }

  .dndflow {
    flex-direction: row;
    display: flex;
    flex-grow: 2;
    height: 800;
  }
  
  .dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
    flex-grow: 0.6;
  }
  
  .dndflow aside .description {
    margin-bottom: 10px;
  }
  
  .dndflow .dndnode {
    height: 20px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
  }
  
  .dndflow .dndnode.input {
    border-color: #0041d0;
  }
  
  .dndflow .dndnode.output {
    border-color: #ff0072;
  }
  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
  .dndflow .selectall {
    margin-top: 10px;
  }
  
  .reactflow-wrapper {

  }
  @media screen and (min-width: 768px) {
    .dndflow {
      /*flex-direction: row;*/
    }
  
    .dndflow aside {
      width: 20%;
      max-width: 250px;
    }

  }